import React,{useState} from 'react';
import Header from '../components/Header'
import { HStack, Box, Text, Image, Link, Button, Heading } from '@chakra-ui/react';
import { Field, formatQuery, QueryBuilder, RuleGroupType } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
function Dashboard(){
	
	const fields: Field[] = [
  { name: 'sender', label: 'Sender' },
  { name: 'message', label: 'Message' },
];

	const [query, setQuery] = useState({
    combinator: 'and',
    rules: [],
  });


	return(
		<>
			<Header />

			<Box className="body_section">
				<Heading className="main_heading">Dashboard</Heading>

			</Box>
		</>
	)
}

export default Dashboard;





