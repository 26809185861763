import React,{useState,useEffect} from 'react';
import Header from '../components/Header'
import { HStack, Box, Text, Image, Link, Button, Heading } from '@chakra-ui/react';

import List from './rule/List'
import Create from './rule/Create'
import Edit from './rule/Edit'
function Rule(){
	 const [ getPage, setGetPage ] = useState(false);

	 useEffect(() => {
        loadPage('list')
       
       
        
    }, []);
	
	const loadPage = (page_id,rs=null) => {

		if(page_id == 'list'){
			setGetPage(<List loadPage={loadPage} />)
		}

		if(page_id == 'create'){
			setGetPage(<Create loadPage={loadPage} />)
		}

		if(page_id == 'edit'){
			setGetPage(<Edit rs={rs} loadPage={loadPage} />)
		}
		
	} 

	return(
		<>
			<Header />

			{getPage}
		</>
	)
}

export default Rule;





