import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
    useField,
    useFormik,
} from 'formik';

export function Form(props) {
    return (
        <Formik
            {...props}
        >
         
            <FormikForm className="needs-validation" noValidate="">
                {props.children}
            </FormikForm>
           
        </Formik>)
}

export default Form;