import React,{useState,useEffect} from 'react';
import Header from '../components/Header'
import { HStack, Box, Text, Image, Link, Button, Heading } from '@chakra-ui/react';
import { Field, formatQuery, QueryBuilder, RuleGroupType } from 'react-querybuilder';
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField,TextAreaField} from '../components/form'
import 'react-querybuilder/dist/query-builder.css';
import { fetchWrapper } from '../helpers';

import jsonata from 'jsonata';
function TestFirewall(){
	const [list,setList] = useState([])
	const [smsStatus,setSmsStatus] = useState('')
	const [submitVal,setSubmitVal] = useState(false)
	const fields: Field[] = [
  { name: 'sender', label: 'Sender' },
  { name: 'message', label: 'Message' },
];

	const [query, setQuery] = useState({
    combinator: 'and',
    rules: [],
  });


	const [formData, setFormData] = useState({
    sender: '',
    message: '',
  
   
    
  });

	 useEffect(() => {
        loadList()
    }, []);

	const loadList = async () => {
  		
      const url = `${process.env.REACT_APP_API_URL}/rule/list?page=1`;
      const data  = await fetchWrapper.get(url)
      setList(data.data.rows);
     
  }


const FormSchema = Yup.object().shape({
	   sender: Yup.string().required('Required'),
	   message: Yup.string().required('Required'),
	   
	  
	  
	 

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		   
  		   await test(data)
	}

	const test = async (data) => {

			setSmsStatus('sent')
			const newList =  list.map(async item => {

				 const expression = jsonata(item.rule);
				 const result = await expression.evaluate(data);  // returns 24
				 console.log(result)
				 let ss = '1' 
				
				 
				 if (typeof(result) == "undefined"){
				 	ss = 'Skip'
				 }else{

				 	if(result){
				 	 ss = item.action_id
				 	}
				 }

				 if(ss == 2){
				 	setSmsStatus('block')
				 }
				 
				return {name:item.name,rule:item.rule,statusValue:ss,action_id:item.action_id}
			})

			Promise.all(newList).then(function(results) {
			    setList(results)
			})
			
			

		   

		    
		    
		
	}

	return(
		<>
		<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >
			<Header />

			<Box className="body_section">
				<Heading className="main_heading">Test Firewall</Heading>



			</Box>

			<div className="body_section">
				<div className="table_section">
					<Box className="page_data">
						<HStack alignItems="start">
							<Box w="30%" >

							<Heading as='h4' size='md'>
					   SMS Message
					  </Heading>
							

								<InputText name="sender" title="Sender ID"  />

								<br />
								<TextAreaField name="message" title="Message"  />


								<br />
								<Button py="10px" px="25px" color="#fff" bg="#0A83FF" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
						            mb={4}
						            
						            type='submit'
				          		>
				           		 Send Message
		          				</Button>
							</Box>

							<Box w="50%">
								

							<Heading as='h4' size='md' ml="5">
					   Global Rules
					  </Heading>

								{list?.map((rs,index) => {

					    		return(
					    				<HStack p="2" borderRadius="6" m="5" border='1px' borderColor='gray.200'>
						    				<Box w="75%">{rs.name}</Box>
						    				<Box textAlign='right' w="25%">

						    					{rs.statusValue == '1' &&

						    						<Text color="green">Allow</Text>
						    					}


						    					{rs.statusValue == '2' &&

						    						<Text color="red">Block</Text>
						    					}
						    				</Box>
					    				</HStack>
					    			)


					    		})}

					    		{smsStatus == 'block' &&
					    			<Button m="5" height="50" color="#000" fontWeight="bold" background="#FF0000" w="95%">SMS Blocked</Button>
					    		}

					    		{smsStatus == 'sent' &&

					    			<Button m="5" height="50" color="#fff" fontWeight="bold" background="green" w="95%">SMS Sent</Button>
					    		}
							</Box>

							
						</HStack>
					</Box>
				</div>
			</div>
			</Form>
		</>
	)
}

export default TestFirewall;





