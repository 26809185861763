import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../components/form'
import { HStack, Box, Text, Image, Link,  Heading,Code } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';
import { Field, formatQuery, QueryBuilder, RuleGroupType } from 'react-querybuilder';

import 'react-querybuilder/dist/query-builder-layout.css';
import './styles.css';


function Create({loadPage}){

	

	const fields: Field[] = [
  { name: 'sender', label: 'Sender' },
  { name: 'message', label: 'Message' },
];

	const [query, setQuery] = useState({
    combinator: 'and',
    rules: [],
  });

	const [formData, setFormData] = useState({
    name: '',
    rule_set_id: '',
    action_id: '',
    priority_id:'',
    sensitivity_id:'',
   
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   rule_set_id: Yup.string().required('Required'),
	   
	  
	   action_id: Yup.string().required('Required'),
	   priority_id: Yup.string().required('Required'),
	
	   sensitivity_id: Yup.string().required('Required'),
	 

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  data.rule = formatQuery(query, 'JSONata')
		  data.query_rule = JSON.stringify(query)
	       
	     const url = `${process.env.REACT_APP_API_URL}/rule/create`;
  		 await fetchWrapper.post(url,data)
  		 
  		loadPage('list')
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
  		 
	}

	


	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >
			<div className="body_section">
      	
				<HStack justifyContent="space-between" mb="25px">
					<Box>
						<Text fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000" my="0px">Rules</Text>
					</Box>

					<Box>
						
					</Box>		
				</HStack>

				<div className="table_section">
					
					
				
				<Box className="page_data">
					<HStack>
					<Box w="25%">
			 			<InputText name="name" title="Rule Name"  />
			 		</Box>

			 		<Box w="25%">
			 			<SelectField list={{rows:[{name:"Rule 1",id:'1'},
                    {name:"Rule 2",id:'2'}]}} name="rule_set_id" title="Rule Set"  />
			 		</Box>

			 		<Box w="25%">
			 			<SelectField list={{rows:[{name:"1",id:'1'},
                    			{name:"2",id:'2'}]}} name="priority_id" title="Priority"  />
			 		</Box>

			 		<Box w="25%">
			 			<SelectField list={{rows:[{name:"High",id:'1'},
                    			{name:"Low",id:'2'}]}} name="sensitivity_id" title="Sensitivity"  />
			 		</Box>
			 		</HStack>
			 		<br />
					<QueryBuilder query={query} 
					showCombinatorsBetweenRules
					onQueryChange={setQuery} fields={fields} 

					controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
					/>

					
					<br />

					<Heading as='h4' size='md'>
					   Preview
					  </Heading>

      				<Code p="3" borderRadius="6">{formatQuery(query, 'JSONata')}</Code>

      				<br /><br />
      				
      				<Box w="25%">

      				<SelectField list={{rows:[{name:"Allow",id:'1'},
                    			{name:"Block",id:'2'},{name:"Flag",id:'3'}]}} name="action_id" title="Perform following action"  />
				
                   <br />
                    <Button py="10px" px="25px" color="#fff" bg="#0A83FF" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Create Rule
		          		</Button>

		          		<Button py="10px" ml="2" px="25px" color="#000" bg="#ccc" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
				            mb={4}
				            
				            type='button'

				            onClick={() => loadPage('list')}
		          		>
		           		 Back
		          		</Button>


                    </Box>
				</Box>
			
				</div>
			</div>

      		</Form>
		</>

			

	)
}

export default Create;