import logo from './logo.svg';
import './index.css';
import React from 'react';
import {Link } from 'react-router-dom';
import { BsCaretDownFill } from "react-icons/bs";
import { ChakraProvider } from '@chakra-ui/react';
import Dashboard from './pages/Dashboard.js'
import Rule from './pages/Rule';
import TestFirewall from './pages/TestFirewall';

import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { PrivateRoute } from './components/PrivateRoute';
function App() {
  return (
    <ChakraProvider>
            <BrowserRouter>
        <Routes>            
          <Route path="/" element={
           
                                <Dashboard />
                                
                           

           } />

           <Route path="/test-firewall" element={
           
                                <TestFirewall />
                                
                           

           } />
          

           } />
           <Route path="/dashboard" element={
           
                                <Dashboard />
                                
                           

           } />
          

           } />
          <Route path="/rule" element={

           
                                <Rule />
                                
                            

            } />


            
         
         
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
