import React from 'react';
import { HStack, Box, Text, Image, Button } from '@chakra-ui/react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import logo from "../images/logo.png";
import { BsCaretDownFill } from "react-icons/bs";
import { Link,useNavigate } from "react-router-dom";
function Header(){
	return(
		<>
			<Box className="header_section">
				<Box>
					<Link>
						<Image src={logo} />
					</Link>
				</Box>

				<Box>
					<HStack>
						<Box me="30px"> 
							<Link className="header_link" to="/">Dashboard</Link>
						</Box>

						<Box me="30px">
							<Link className="header_link" to="/rule">Rules</Link>
						</Box>

						<Box me="30px">
							<Link className="header_link" to="/test-firewall">Test Firewall</Link>
						</Box>

						<Box me="30px">
							<Link className="header_link">CDR’s</Link>
						</Box>

						<Box me="30px">
							<Link className="header_link">Firewall</Link>
						</Box>

						<Box me="30px">
							<Link className="header_link">Reports</Link>
						</Box>

						<Box>
							<Link className="header_link">Settings</Link>
						</Box>
					</HStack>
				</Box>

				<Box>
					<Menu>
					  <MenuButton>
					    <HStack className="user_section">
					    	<Box className="user_img_section">
					    		<Text fontSize="16px" fontWeight="700" color="#fff" fontFamily='"Noto Sans", sans-serif'>DA</Text>
					    	</Box>

					    	<Box>
					    		<Text fontSize="12px" fontWeight="400" color="#fff" fontFamily='"Noto Sans", sans-serif' textAlign="left">Welcome</Text>
					    		<Text fontSize="14px" fontWeight="400" color="#fff" fontFamily='"Noto Sans", sans-serif' textAlign="left" display="flex" alignItems="center">Dilawar Abbas  <BsCaretDownFill fontSize="10px" /></Text>
					    	</Box>
					    </HStack>
					  </MenuButton>

					  <MenuList>
					    <MenuItem>Logout</MenuItem>
					  </MenuList>
					</Menu>
				</Box>
			</Box>
		</>
	)
}

export default Header;